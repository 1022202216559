import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NzImageModule, NzImageService } from 'ng-zorro-antd/image';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { ProductPricePipe } from '../../../pipes/product-price.pipe';
import { FileApiService } from '../../../services/api/file-api.service';

@Component({
  selector: 'app-product-list-item',
  imports: [
    NzImageModule,
    FileurlPipe,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    ProductPricePipe,
    MatBadgeModule,
    SlideupDirective,
  ],
  templateUrl: './product-list-item.component.html',
  styleUrl: './product-list-item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductListItemComponent {
  @Input() index = 0;
  @Input({ required: true }) data: any;

  constructor(
    private nzImageService: NzImageService,
    private fileApi: FileApiService
  ) {}

  view(i: number) {
    const images = [];
    for (const img of this.data.product.images) {
      images.push({
        src: this.fileApi.fileurl(img),
      });
    }
    const ref = this.nzImageService.preview(images);
    ref.switchTo(i);
  }

  delay(i: number) {
    if (i > 5) {
      return 200;
    }
    return 100 * i;
  }
}
