import { Platform } from '@angular/cdk/platform';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
import { PromotionApiService } from '../../../services/api/promotion-api.service';
import { HomeProductItemComponent } from '../../product/home-product-item/home-product-item.component';

@Component({
  selector: 'app-home-section-category',
  imports: [
    MatDividerModule,
    HomeProductItemComponent,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    SlideupDirective,
  ],
  templateUrl: './home-section-category.component.html',
  styleUrl: './home-section-category.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeSectionCategoryComponent implements OnChanges {
  @Input({ required: true }) data: any;

  constructor(
    private promotionApi: PromotionApiService,
    private platform: Platform
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.findProducts();
  }

  autoPlayDelay = Math.floor(Math.random() * (5000 - 3000 + 1)) + 3000;

  products: any[] = [];
  loading = false;
  async findProducts() {
    try {
      this.loading = true;
      const r = await this.promotionApi.findProductCategoryPromotionProducts(
        this.data.id
      );
      this.products = r.data || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
