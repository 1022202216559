import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';

@Component({
  selector: 'app-oem-section-factory',
  imports: [NgOptimizedImage, MatIconModule, SlideupDirective],
  templateUrl: './oem-section-factory.component.html',
  styleUrl: './oem-section-factory.component.scss',
})
export class OemSectionFactoryComponent {
  workshops: string[] = [
    'images/oem/workshop3.webp',
    'images/oem/workshop2.webp',
    'images/oem/workshop1.webp',
    'images/oem/workshop4.webp',
  ];
}
