import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessApiService {
  constructor(private http: HttpService) {}

  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/business' + path, queryMap);
  }

  createBusinessInquiry({
    name,
    email,
    message,
  }: {
    name: string;
    email: string;
    message: string;
  }) {
    const url = this.buildurl('/inquiry');
    const body = { name, email, message };
    return this.http.post(url, body);
  }
}
