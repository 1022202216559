import { Component, Input } from '@angular/core';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
import { ShoppingOrderItemItemComponent } from '../shopping-order-item-item/shopping-order-item-item.component';

@Component({
  selector: 'app-shopping-order-item-list',
  imports: [ShoppingOrderItemItemComponent, SlideupDirective],
  templateUrl: './shopping-order-item-list.component.html',
  styleUrl: './shopping-order-item-list.component.scss',
})
export class ShoppingOrderItemListComponent {
  @Input({ required: true }) items: any[] = [];
}
