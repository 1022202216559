import { Platform } from '@angular/cdk/platform';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
import { PromotionApiService } from '../../../services/api/promotion-api.service';
import { HomeProductItemComponent } from '../../product/home-product-item/home-product-item.component';

@Component({
  selector: 'app-home-section-featured-products',
  imports: [
    MatDividerModule,
    HomeProductItemComponent,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    SlideupDirective,
  ],
  templateUrl: './home-section-featured-products.component.html',
  styleUrl: './home-section-featured-products.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeSectionFeaturedProductsComponent implements OnInit {
  constructor(
    private promotionApi: PromotionApiService,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    this.findFeaturedProducts();
  }

  get isBrowser() {
    return this.platform.isBrowser;
  }

  products: any[] = [];
  loading = false;
  async findFeaturedProducts() {
    try {
      this.loading = true;
      const r = await this.promotionApi.findFeaturedProducts();
      this.products = r.data || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
