<div class="page-content">
  <h1>Order List</h1>
  <div class="items">
    @if (loading) {
    <div class="loading">
      <mat-spinner [diameter]="60"></mat-spinner>
    </div>
    }@else { @for (item of items ; track $index) {
    <app-shopping-order-item
      [data]="item"
      appSlideRight
      [slideRightDelay]="$index * 150"
    ></app-shopping-order-item>
    }@empty {
    <div class="loading">
      <nz-empty nzNotFoundContent="No Order Yet"></nz-empty>
    </div>
    } }

    <div class="flex-spacer"></div>

    <mat-paginator
      [length]="total"
      [pageSize]="pageSize"
      [pageIndex]="page - 1"
      (page)="onPageChanged($event)"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</div>
