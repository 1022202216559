<app-home-section-1></app-home-section-1>
<app-home-section-featured-products></app-home-section-featured-products>
<app-home-oem-banner
  appSlideRight
  [onIntersection]="true"
  [slideRightDelay]="250"
  [slideRightX]="-50"
></app-home-oem-banner>
@for (category of categories; track $index) {
<app-home-section-category [data]="category"></app-home-section-category>
}

<div class="action">
  <a mat-flat-button routerLink="/shopping/products">View More</a>
</div>
