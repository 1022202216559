<mat-toolbar>
  <ul>
    <li>
      <a aria-label="go to home page" mat-button routerLink="/">Home</a>
    </li>
    <li>
      <a
        aria-label="go to products page"
        mat-button
        routerLink="/shopping/products"
        >Products</a
      >
    </li>
    <li>
      <a aria-label="go to oem page" mat-button routerLink="/oem" class="oem">
        <span>OEM</span></a
      >
    </li>
    <li>
      <button
        aria-label="open contact dialog"
        mat-button
        (click)="openContactDialog()"
      >
        Contact
      </button>
    </li>
    <li>
      <a aria-label="go to faq page" mat-button routerLink="/faq">FAQ</a>
    </li>
    <li>
      <a aria-label="go to about page" mat-button routerLink="/about">About</a>
    </li>
  </ul>
  <button
    mat-icon-button
    (click)="onMenuToggle.emit(true)"
    aria-label="open drawer"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <div class="flex-spacer"></div>
  <app-search-box></app-search-box>
  <app-user-shopping-bag-button
    (click)="onCartToggle.emit(true)"
  ></app-user-shopping-bag-button>
  <app-user-avatar-button></app-user-avatar-button>
</mat-toolbar>
