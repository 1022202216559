import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { CartService } from '../../../services/cart.service';
import { TitleService } from '../../../services/title.service';

import { Platform } from '@angular/cdk/platform';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from '../../../../environments/environment';
import { ShoppingCartItemComponent } from '../../../components/shopping/shopping-cart-item/shopping-cart-item.component';
import { ShoppingCartSummaryPanelComponent } from '../../../components/shopping/shopping-cart-summary-panel/shopping-cart-summary-panel.component';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
@Component({
  selector: 'app-shopping-cart-page',
  imports: [
    ShoppingCartItemComponent,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    NzEmptyModule,
    MatInputModule,
    MatFormFieldModule,
    ShoppingCartSummaryPanelComponent,
    SlideupDirective,
  ],
  templateUrl: './shopping-cart-page.component.html',
  styleUrl: './shopping-cart-page.component.scss',
})
export class ShoppingCartPageComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    public cartService: CartService,
    private platform: Platform
  ) {
    this.titleService.set('Shopping Cart');
  }

  loading = false;

  ngOnInit(): void {
    if (environment.isDevelopment && !this.platform.isBrowser) {
      return;
    }
    this.cartService.findShoppingCartItems();
  }

  async onItemRemoved(data: any) {
    try {
      this.loading = true;
      await this.cartService.deleteUserShoppingCartItem(data.id);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
