<div class="header">
  <h1 appSlideup [slideUpDelay]="200">Super Apparel</h1>
  <h3 appSlideup [slideUpDelay]="400">Yiwu Xuanyu Apparel Co., Ltd.</h3>
  <p appSlideup [slideUpDelay]="600">
    is a leading manufacturer and exporter specializing in women's light woven
    wear.
  </p>
  <p appSlideup [slideUpDelay]="800">
    We excel in design, development, and production, providing high-quality
    apparel to retailers, designer brands, and wholesalers.
  </p>
  <p appSlideup [slideUpDelay]="1000">
    Our commitment to excellence and long-term business partnerships drives our
    success in the global market.
  </p>
  <p appSlideup [slideUpDelay]="1200">
    If you have any questions, please be free to ask.
  </p>
  <p appSlideup [slideUpDelay]="1400">We'll try best to meet your needs.</p>
  <div class="content" appSlideup [slideUpDelay]="1400">
    <app-contact-whatsapp-button></app-contact-whatsapp-button>
    <app-contact-email-button></app-contact-email-button>
  </div>
</div>
