import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
import { BusinessApiService } from '../../../services/api/business-api.service';
import { MessageService } from '../../../services/message.service';
import { validateFormGroup } from '../../../utils/form';

@Component({
  selector: 'app-oem-section-contact',
  imports: [
    MatIconModule,
    SlideupDirective,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './oem-section-contact.component.html',
  styleUrl: './oem-section-contact.component.scss',
})
export class OemSectionContactComponent {
  formGroup: FormGroup;
  constructor(
    private fb: FormBuilder,
    private message: MessageService,
    private businessApi: BusinessApiService
  ) {
    this.formGroup = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', []],
    });
  }

  loading: boolean = false;
  async submit() {
    if (this.loading) {
      return;
    }
    const value = validateFormGroup(this.formGroup);
    if (!value) {
      return;
    }
    try {
      this.loading = true;
      const r = await this.businessApi.createBusinessInquiry(value);
      if (r.status !== 0) {
        this.message.open('Unknown Error', 'OK');
      } else {
        this.message.open(
          'Thank you for reaching out! We’ll get back to you soon.',
          'OK'
        );
      }
    } catch (error) {
      this.message.open('Network Error', 'OK');
    } finally {
      this.loading = false;
    }
  }
}
