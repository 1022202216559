import { Routes } from '@angular/router';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { AccountSettingPageComponent } from './pages/account/account-setting-page/account-setting-page.component';
import { EmailVerificationPageComponent } from './pages/account/email-verification-page/email-verification-page.component';
import { PasswordResetPageComponent } from './pages/account/password-reset-page/password-reset-page.component';
import { UserAddressPageComponent } from './pages/account/user-address-page/user-address-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MainLayoutPageComponent } from './pages/main-layout-page/main-layout-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { OemPageComponent } from './pages/oem-page/oem-page.component';
import { PrivacyPolicyPageComponent } from './pages/policy/privacy-policy-page/privacy-policy-page.component';
import { TermsOfUsePageComponent } from './pages/policy/terms-of-use-page/terms-of-use-page.component';
import { ShoppingCartPageComponent } from './pages/shopping/shopping-cart-page/shopping-cart-page.component';
import { ShoppingOrderListPageComponent } from './pages/shopping/shopping-order-list-page/shopping-order-list-page.component';
import { ShoppingOrderPageComponent } from './pages/shopping/shopping-order-page/shopping-order-page.component';
import { ShoppingProductListPageComponent } from './pages/shopping/shopping-product-list-page/shopping-product-list-page.component';
import { ShoppingProductListingPageComponent } from './pages/shopping/shopping-product-listing-page/shopping-product-listing-page.component';
import { ShoppingProductPageComponent } from './pages/shopping/shopping-product-page/shopping-product-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomePageComponent,
      },
      {
        path: 'about',
        pathMatch: 'full',
        component: AboutPageComponent,
      },
      {
        path: 'oem',
        pathMatch: 'full',
        component: OemPageComponent,
      },
      {
        path: 'faq',
        pathMatch: 'full',
        component: FaqPageComponent,
      },
      {
        path: 'login',
        pathMatch: 'full',
        component: LoginPageComponent,
      },
      {
        path: 'signup',
        pathMatch: 'full',
        component: SignupPageComponent,
      },
      {
        path: 'shopping',
        children: [
          {
            path: 'cart',
            pathMatch: 'full',
            component: ShoppingCartPageComponent,
          },
          {
            path: 'order/:id',
            pathMatch: 'full',
            component: ShoppingOrderPageComponent,
          },
          {
            path: 'orders',
            pathMatch: 'full',
            component: ShoppingOrderListPageComponent,
          },
          {
            path: 'products',
            pathMatch: 'full',
            component: ShoppingProductListPageComponent,
          },
          {
            path: 'product/:id',
            pathMatch: 'full',
            component: ShoppingProductPageComponent,
          },
          {
            path: 'product/list/:id',
            pathMatch: 'full',
            component: ShoppingProductListingPageComponent,
          },
        ],
      },
      {
        path: 'account',
        children: [
          {
            path: 'address',
            pathMatch: 'full',
            component: UserAddressPageComponent,
          },
          {
            path: 'setting',
            pathMatch: 'full',
            component: AccountSettingPageComponent,
          },
          {
            path: 'email-verification',
            pathMatch: 'full',
            component: EmailVerificationPageComponent,
          },
          {
            path: 'password-reset',
            pathMatch: 'full',
            component: PasswordResetPageComponent,
          },
        ],
      },
      {
        path: 'policy',
        children: [
          {
            path: 'terms-of-use',
            pathMatch: 'full',
            component: TermsOfUsePageComponent,
          },
          {
            path: 'privacy',
            pathMatch: 'full',
            component: PrivacyPolicyPageComponent,
          },
        ],
      },
      {
        path: '**',
        component: NotFoundPageComponent,
      },
    ],
  },
];
