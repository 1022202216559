import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NzImageModule, NzImageService } from 'ng-zorro-antd/image';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { FileApiService } from '../../../services/api/file-api.service';
@Component({
  selector: 'app-product-images-swiper',
  imports: [
    FileurlPipe,
    MatIconModule,
    NzImageModule,
    MatButtonModule,
    SlideupDirective,
  ],
  templateUrl: './product-images-swiper.component.html',
  styleUrl: './product-images-swiper.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductImagesSwiperComponent {
  @Input({ required: true }) images: string[] = [];
  @ViewChild('swiper') swiperRef!: ElementRef;

  constructor(
    private imageService: NzImageService,
    private fileApi: FileApiService
  ) {}

  view(i: number) {
    const images = [];
    for (const img of this.images) {
      images.push({
        src: this.fileApi.fileurl(img),
      });
    }
    const ref = this.imageService.preview(images);
    ref.switchTo(i);
  }
}
