<a class="page-content" routerLink="/oem">
  <h3>Your Brand, Our Craft.</h3>
  <p>
    Seamlessly transform your designs into high-quality apparel with our trusted
    <span class="highlight">OEM</span> solutions.
  </p>
  <img ngSrc="images/oem/banner.webp" fill />

  <!-- <mat-divider></mat-divider> -->

  <!-- <swiper-container
    space-between="20"
    autoplay-delay="4500"
    autoplay-disable-on-interaction="false"
    slides-per-view="auto"
    #swiper
  >
    @for (productImage of productImages; track $index) {
    <swiper-slide>
      <img [ngSrc]="productImage" width="300" height="300" />
    </swiper-slide>
    }
  </swiper-container> -->
</a>
