import { Platform } from '@angular/cdk/platform';
import { NgOptimizedImage } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SendResetPasswordEmailDialogComponent } from '../../components/user/send-reset-password-email-dialog/send-reset-password-email-dialog.component';
import { CustomReuseStrategy } from '../../route-reuse-strategy';
import { ApiStatus } from '../../services/api/status';
import { UserApiService } from '../../services/api/user-api.service';
import { AuthService } from '../../services/auth.service';
import { CartService } from '../../services/cart.service';
import { MessageService } from '../../services/message.service';
import { TitleService } from '../../services/title.service';
import { validateFormGroup, validateNonEmptyString } from '../../utils/form';
declare const window: any;

@Component({
  selector: 'app-login-page',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatCheckboxModule,
    RouterModule,
    MatDividerModule,
    MatDialogModule,
    MatDividerModule,
    NgOptimizedImage,
    MatRippleModule,
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent implements AfterViewInit {
  formGroup: FormGroup;
  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private userApi: UserApiService,
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
    private cartService: CartService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private platform: Platform
  ) {
    this.titleService.set('Login');

    this.formGroup = this.fb.group({
      email: [
        platform.isBrowser ? localStorage.getItem('user.email') || '' : '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: ['', [Validators.required, validateNonEmptyString]],
      remember: [false],
    });
  }

  ngAfterViewInit(): void {
    if (this.platform.isBrowser) {
      window['gapi'].load('auth2', () => {
        const auth2 = window['gapi'].auth2.init({
          client_id: environment.google.oauth.clientID,
          fetch_basic_profile: true,
          scope: 'profile email',
          plugin_name: 'login', //you can use any name here
        });

        // 渲染 Google 登录按钮
        auth2.attachClickHandler(
          document.getElementById('google-signin-btn'),
          {},
          (v: any) => this.onGoogleSignIn(v),
          (v: any) => {}
        );
      });
    }
  }

  async onGoogleSignIn(v: any) {
    const idToken = v.Ic.id_token;
    try {
      this.loading = true;
      const r = await this.userApi.loginWithGoogle(idToken);
      if (r.status === ApiStatus.OK) {
        this.afterLogin(r.data);
      } else {
        this.messageService.open('Unknown Error', 'OK');
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  loading = false;
  submit(e: SubmitEvent) {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    this.login(values);
  }

  async login(values: any) {
    try {
      this.loading = true;
      const r = await this.userApi.login(
        values.email,
        values.password,
        values.remember
      );
      if (values.remember) {
        localStorage.setItem('user.email', values.email);
      } else {
        localStorage.removeItem('user.email');
      }
      if (r.status === ApiStatus.ErrUserEmailNotFound) {
        this.messageService
          .open('Email not found', 'Signup')
          ?.onAction()
          .subscribe(() => {
            this.router.navigate(['/signup']);
          });
      } else if (r.status === ApiStatus.ErrUserPasswordIncorrect) {
        this.messageService.open('Password incorrect', 'OK');
      } else if (r.status === ApiStatus.ErrUserStatusBanned) {
        this.messageService.open('Sorry, this account has been banned', 'OK');
      } else if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.afterLogin(r.data);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
  forgotPassword() {
    this.dialog.open(SendResetPasswordEmailDialogComponent, {
      width: '480px',
      autoFocus: false,
      data: this.formGroup.get('email')?.value,
    });
  }

  afterLogin(data: any) {
    CustomReuseStrategy.clear();
    this.authService.self.set(data);
    this.cartService.findShoppingCartItems();
    const url = this.route.snapshot.queryParams['r'] || '/';
    try {
      this.router.navigateByUrl(url, { replaceUrl: true });
    } catch (error) {
      this.router.navigateByUrl('/', { replaceUrl: true });
    }
  }
}
