import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ContactEmailButtonComponent } from '../../components/common/contact-email-button/contact-email-button.component';
import { ContactWhatsappButtonComponent } from '../../components/common/contact-whatsapp-button/contact-whatsapp-button.component';
import { SlideupDirective } from '../../directives/animations/slideup.directive';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-about-page',
  imports: [
    MatButtonModule,
    MatIconModule,
    ContactEmailButtonComponent,
    ContactWhatsappButtonComponent,
    SlideupDirective,
  ],
  templateUrl: './about-page.component.html',
  styleUrl: './about-page.component.scss',
})
export class AboutPageComponent {
  constructor(private title: TitleService) {
    this.title.set('About');
  }
}
