import { NgOptimizedImage } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-home-oem-banner',
  imports: [RouterModule, NgOptimizedImage, MatDividerModule],
  templateUrl: './home-oem-banner.component.html',
  styleUrl: './home-oem-banner.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeOemBannerComponent {
  productImages: string[] = [];
}
