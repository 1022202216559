<div class="page-content">
  <div class="factory">
    <div class="introduction">
      <h2 appSlideup [onIntersection]="true" [slideUpDelay]="100">
        <mat-icon class="material-symbols-outlined" inline>factory</mat-icon>
        <span>Our Factory</span>
      </h2>
      <p appSlideup [onIntersection]="true" [slideUpDelay]="300">
        Discover the strength behind our OEM business. With state-of-the-art
        facilities, advanced production lines, and a highly skilled workforce,
        we deliver top-quality products tailored to your exact specifications.
        Our commitment to precision and efficiency ensures that every product
        meets the highest industry standards.
      </p>
    </div>
    <img
      ngSrc="images/oem/factory.webp"
      width="600"
      height="450"
      alt="factory"
      appSlideup
      [onIntersection]="true"
      [slideUpDelay]="350"
    />
  </div>
  <div class="workshop">
    @for (workshop of workshops; track $index) {
    <div>
      <img
        [ngSrc]="workshop"
        fill
        alt="workshop"
        appSlideup
        [onIntersection]="true"
        [slideUpDelay]="300 + $index * 200"
      />
    </div>
    }
  </div>
</div>
