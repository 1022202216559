import { Component } from '@angular/core';

@Component({
  selector: 'app-oem-section-products',
  imports: [],
  templateUrl: './oem-section-products.component.html',
  styleUrl: './oem-section-products.component.scss'
})
export class OemSectionProductsComponent {

}
