<img
  class="logo"
  ngSrc="images/logo.webp"
  width="400"
  height="400"
  alt="Super Apparel logo"
/>

<h1 appSlideup [slideUpDelay]="100">Super Apparel</h1>
<p appSlideup [slideUpDelay]="300">
  We're a China-based apparel supplier with 7 years of experience in exporting
  apparel, bags, and accessories to the U.S. market. We deliver products that
  align with American trends while maintaining high quality and competitive
  pricing.
</p>
<p appSlideup [slideUpDelay]="500">
  Feel free to contact us for both <b>OEM</b> services and
  <b>ready-to-ship</b> products.
</p>
<!-- <button
  mat-raised-button
  (click)="openContactDialog()"
  appSlideup
  [slideUpDelay]="700"
>
  Contact Us
</button> -->
<div class="contact">
  <app-contact-whatsapp-button></app-contact-whatsapp-button>
  <app-contact-email-button></app-contact-email-button>
</div>
