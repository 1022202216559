<div class="page-content">
  <h1 appSlideup [slideUpDelay]="100">
    OEM Apparel Manufacturing – Your Brand, Our Craft
  </h1>

  <p appSlideup [slideUpDelay]="250">
    <b>Yiwu Xuanyu Apparel Co., Ltd.</b> was established in 2015 and has over 8
    years of experience in the American fashion market, specializing in design,
    development, and production.
  </p>
  <p appSlideup [slideUpDelay]="400">
    As a growing manufacturer and exporter of fashion garments based in
    Zhejiang, China, we are strategically located near the world's largest
    fabric market. This gives our designers the advantage of sourcing the latest
    fabrics and patterns to create trendy new styles.
  </p>
  <ul>
    <li appSlideup [slideUpDelay]="550">
      <p>🔹 50+ new styles updated twice a week</p>
    </li>
    <li appSlideup [slideUpDelay]="700">
      <p>🔹 Fast sampling in just 7 days</p>
    </li>
  </ul>
  <p appSlideup [slideUpDelay]="850">
    With our expertise, innovation, and efficient production, we help brands
    stay ahead in the ever-evolving fashion industry.
  </p>
</div>
