import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { OemSectionContactComponent } from '../../components/oem/oem-section-contact/oem-section-contact.component';
import { OemSectionFactoryComponent } from '../../components/oem/oem-section-factory/oem-section-factory.component';
import { OemSectionHeaderComponent } from '../../components/oem/oem-section-header/oem-section-header.component';
import { OemSectionProductsComponent } from '../../components/oem/oem-section-products/oem-section-products.component';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-oem-page',
  imports: [
    OemSectionHeaderComponent,
    OemSectionProductsComponent,
    OemSectionFactoryComponent,
    OemSectionContactComponent,
    MatDividerModule,
  ],
  templateUrl: './oem-page.component.html',
  styleUrl: './oem-page.component.scss',
})
export class OemPageComponent {
  constructor(private title: TitleService) {
    this.title.set('OEM Apparel Manufacturing');
  }
}
