import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { environment } from '../../../../environments/environment';
import { ShoppingOrderItemComponent } from '../../../components/shopping/shopping-order-item/shopping-order-item.component';
import { SlideRightDirective } from '../../../directives/animations/slide-right.directive';
import { ShoppingApiService } from '../../../services/api/shopping-api.service';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'app-shopping-order-list-page',
  imports: [
    MatPaginatorModule,
    ShoppingOrderItemComponent,
    MatProgressSpinnerModule,
    NzEmptyModule,
    SlideRightDirective,
  ],
  templateUrl: './shopping-order-list-page.component.html',
  styleUrl: './shopping-order-list-page.component.scss',
})
export class ShoppingOrderListPageComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    private shoppingApi: ShoppingApiService,
    private platform: Platform,
    private route: ActivatedRoute
  ) {
    this.titleService.set('Order List', this.route);
  }
  loading = false;

  ngOnInit(): void {
    if (environment.isDevelopment && !this.platform.isBrowser) {
      return;
    }
    this.findUserOrders();
  }

  queryStatus = '';
  page = 1;
  pageSize = 10;
  total = 0;
  items: any[] = [];
  async findUserOrders() {
    try {
      this.loading = true;
      const r = await this.shoppingApi.findUserOrders({
        status: this.queryStatus,
        page: this.page,
        pageSize: this.pageSize,
      });
      this.items = r.data.list || [];
      this.page = r.data.page;
      this.pageSize = r.data.page_size;
      this.total = r.data.total;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  onPageChanged(e: PageEvent) {
    this.page = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.findUserOrders();
  }
}
