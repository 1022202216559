<div class="page-content">
  <h1>Shopping Cart</h1>

  <div class="content">
    <div class="cart-content">
      @if (cartService.groupedItems().length>0) {
      <div class="items">
        @for (item of cartService.groupedItems(); track $index) {
        <app-shopping-cart-item
          [data]="item"
          (onRemove)="onItemRemoved($event)"
          appSlideup
          [slideUpDelay]="$index * 150"
        >
          @for (extra of item.extra; track $index) {
          <app-shopping-cart-item
            [data]="extra"
            [noTitle]="true"
            (onRemove)="onItemRemoved($event)"
          ></app-shopping-cart-item>
          }
        </app-shopping-cart-item>
        }
      </div>
      }@else{
      <div class="empty">
        <nz-empty nzNotFoundContent="Your cart is empty."></nz-empty>
      </div>
      } @if (loading) {
      <div class="loading">
        <mat-spinner [diameter]="48"></mat-spinner>
      </div>
      }
    </div>

    <app-shopping-cart-summary-panel></app-shopping-cart-summary-panel>
  </div>
</div>
