<div class="page-content">
  <h2 appSlideup [onIntersection]="true" [slideUpDelay]="100">
    <mat-icon class="material-symbols-outlined" inline>phone_in_talk</mat-icon>
    <span>Get in Touch</span>
  </h2>

  <p appSlideup [onIntersection]="true" [slideUpDelay]="200">
    Have questions or need more information about our OEM services? Contact us
    today — our team is ready to assist you with your business needs.
  </p>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-form-field
      appearance="outline"
      appSlideup
      [onIntersection]="true"
      [slideUpDelay]="300"
    >
      <mat-label>Name</mat-label>
      <input
        matInput
        placeholder="Your Name"
        formControlName="name"
        maxlength="32"
      />
      <mat-icon matSuffix>badge</mat-icon>
      <mat-error>
        @if (formGroup.get('name')?.hasError('required')) { Name is required }
      </mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      appSlideup
      [onIntersection]="true"
      [slideUpDelay]="400"
    >
      <mat-label>Email</mat-label>
      <input
        matInput
        placeholder="We will respond to your email"
        formControlName="email"
        maxlength="64"
      />
      <mat-icon matSuffix>mail</mat-icon>
      <mat-error>
        @if (formGroup.get('email')?.hasError('required')) { Email is required
        }@else if(formGroup.get('email')?.hasError('email')){ Invalid email
        address }
      </mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="full"
      appSlideup
      [onIntersection]="true"
      [slideUpDelay]="500"
    >
      <mat-label>Message</mat-label>
      <textarea
        matInput
        placeholder="Leave your message here"
        formControlName="message"
        [rows]="4"
        maxlength="4096"
      ></textarea>
    </mat-form-field>

    <div
      class="actions"
      appSlideup
      [onIntersection]="true"
      [slideUpDelay]="600"
    >
      <button mat-flat-button type="submit">Submit</button>
    </div>
  </form>

  <!-- <div class="contacts">
    <app-contact-whatsapp-button></app-contact-whatsapp-button>
    <app-contact-email-button></app-contact-email-button>
  </div> -->
</div>
