<div class="page-content">
  <div class="header">
    <h2>Featured Products</h2>
    <a routerLink="/shopping/products">view more</a>

    <div class="flex-spacer"></div>
    <button
      mat-icon-button
      (click)="swiper.swiper.slidePrev()"
      aria-label="slide to previous element"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="swiper.swiper.slideNext()"
      aria-label="slide to next element"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <swiper-container
    space-between="20"
    autoplay-delay="5000"
    autoplay-disable-on-interaction="false"
    slides-per-view="auto"
    #swiper
  >
    @for (product of products; track $index) {
    <swiper-slide>
      <app-home-product-item
        [data]="product"
        appSlideup
        [onIntersection]="true"
        [slideUpDelay]="$index > 3 ? 200 : 100 * $index"
      ></app-home-product-item>
    </swiper-slide>
    }
  </swiper-container>
</div>
