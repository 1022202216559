import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  style,
} from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appSlideup]',
  standalone: true,
})
export class SlideupDirective implements AfterViewInit {
  @Input() slideUpDuration: number = 500; // 默认动画时长 (ms)
  @Input() slideUpDelay: number = 0; // 默认延迟 (ms)
  @Input() slideUpY: number = 20;
  @Input() onIntersection: boolean = false;

  opacity: number = 1;

  constructor(
    private el: ElementRef,
    private animationBuilder: AnimationBuilder,
    private platform: Platform
  ) {
    this.opacity = this.el.nativeElement.style.opacity;
    this.el.nativeElement.style.opacity = 0;
  }

  ngAfterViewInit(): void {
    if (!this.platform.isBrowser) {
      return;
    }
    if (this.onIntersection) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target); // Stop observing once the animation has been triggered
            this.applyAnimation();
          }
        });
      });

      observer.observe(this.el.nativeElement); // Target the element you want to animate
    } else {
      this.applyAnimation();
    }
  }

  private applyAnimation(): void {
    // 定义动画
    const animation: AnimationFactory = this.animationBuilder.build([
      style({ opacity: 0, transform: `translateY(${this.slideUpY}px)` }),
      animate(
        `${this.slideUpDuration}ms ${this.slideUpDelay}ms ease-in-out`,
        style({ opacity: 1, transform: 'translateY(0px)' })
      ),
    ]);

    // 创建动画播放器并应用到元素上
    const player = animation.create(this.el.nativeElement);
    player.onDone(() => {
      this.el.nativeElement.style.opacity = this.opacity;
    });
    player.play();
  }
}
