import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
import { ContactDialogComponent } from '../../common/contact-dialog/contact-dialog.component';
import { ContactEmailButtonComponent } from '../../common/contact-email-button/contact-email-button.component';
import { ContactWhatsappButtonComponent } from '../../common/contact-whatsapp-button/contact-whatsapp-button.component';

@Component({
  selector: 'app-home-section-1',
  imports: [
    MatButtonModule,
    MatDialogModule,
    NgOptimizedImage,
    SlideupDirective,
    ContactEmailButtonComponent,
    ContactWhatsappButtonComponent,
  ],
  templateUrl: './home-section-1.component.html',
  styleUrl: './home-section-1.component.scss',
})
export class HomeSection1Component {
  constructor(private dialog: MatDialog) {}

  openContactDialog() {
    this.dialog.open(ContactDialogComponent, {
      width: '200px',
      autoFocus: false,
    });
  }
}
