import { Component } from '@angular/core';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';

@Component({
  selector: 'app-oem-section-header',
  imports: [SlideupDirective],
  templateUrl: './oem-section-header.component.html',
  styleUrl: './oem-section-header.component.scss',
})
export class OemSectionHeaderComponent {}
