<div class="page-content">
  <div class="actionbar">
    <app-product-category-select
      [(ngModel)]="queryCategoryID"
      (ngModelChange)="onCategoryChagend()"
    ></app-product-category-select>
    <app-search-input
      [(ngModel)]="query"
      (search)="search()"
    ></app-search-input>
  </div>
  <mat-divider></mat-divider>
  @if (loading) {
  <div class="loading">
    <mat-spinner [diameter]="64"></mat-spinner>
  </div>
  }@else{
  <div class="items">
    @if (items.length>0) { @for (item of items; track $index) {
    <app-product-item
      [data]="item"
      appSlideup
      [slideUpDelay]="$index * 100"
    ></app-product-item>
    } }@else {
    <div class="empty">
      <mat-icon inline>sentiment_dissatisfied</mat-icon>
      <h2>Sorry!</h2>
      <p>No result found. Plese let me know if you have any problem.</p>
    </div>
    }
  </div>
  }
  <mat-paginator
    #paginator
    (page)="onPageChanged($event)"
    [length]="total"
    [pageSize]="pageSize"
    [showFirstLastButtons]="true"
    [hidePageSize]="true"
    [pageIndex]="page - 1"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
