<div class="page-content">
  <h4>
    <span class="index">{{ index + 1 }}</span>
    <span>{{ data.product.no }}</span>
  </h4>

  <a
    [routerLink]="['/shopping/product', data.product.no]"
    aria-label="go to product page"
  >
    <h3>{{ data.product.name }}</h3>
  </a>
  <div class="images">
    <swiper-container
      grab-cursor="true"
      centered-slides="false"
      slides-per-view="auto"
      space-between="0"
      #swiper
    >
      @for (image of data.product.images; track $index) {
      <swiper-slide (click)="view($index)">
        <img
          src="{{ image | fileurl }}"
          alt="product image {{ $index + 1 }}"
          appSlideup
          [slideUpDelay]="delay($index)"
          [slideUpY]="50"
          [onIntersection]="true"
        />
      </swiper-slide>
      }
    </swiper-container>
  </div>
  <div class="price">
    $ <span>{{ data.product | productPrice }}</span>
  </div>
</div>
