import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchInputComponent } from '../../../components/common/search-input/search-input.component';
import { ProductCategorySelectComponent } from '../../../components/product/product-category-select/product-category-select.component';
import { ProductItemComponent } from '../../../components/product/product-item/product-item.component';
import { SlideupDirective } from '../../../directives/animations/slideup.directive';
import { ProductApiService } from '../../../services/api/product-api.service';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'app-shopping-product-list-page',
  imports: [
    FormsModule,
    ProductCategorySelectComponent,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    SearchInputComponent,
    MatDividerModule,
    MatButtonModule,
    ProductItemComponent,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    SlideupDirective,
  ],
  templateUrl: './shopping-product-list-page.component.html',
  styleUrl: './shopping-product-list-page.component.scss',
})
export class ShoppingProductListPageComponent {
  query = '';
  queryCategoryID = 0;
  loading = false;
  page = 1;
  pageSize = 16;
  constructor(
    private title: TitleService,
    private route: ActivatedRoute,
    private productApi: ProductApiService,
    private router: Router
  ) {
    this.title.set('Products', this.route);
    this.route.queryParams.subscribe((r) => {
      this.query = r['q'] || '';
      this.queryCategoryID = parseInt(r['c']) || 0;
      this.page = parseInt(r['p']) || 1;
      this.findProducts();
    });
  }

  search() {
    this.page = 1;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        q: this.query,
        c: this.queryCategoryID,
        p: this.page,
        t: new Date().getTime(),
      },
      queryParamsHandling: 'merge',
    });
  }

  onCategoryChagend() {
    this.search();
  }

  onPageChanged(e: PageEvent) {
    this.page = e.pageIndex + 1;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        q: this.query,
        c: this.queryCategoryID,
        p: this.page,
        t: new Date().getTime(),
      },
      queryParamsHandling: 'merge',
    });
  }

  items: any[] = [];
  total = 0;
  async findProducts() {
    try {
      this.loading = true;
      const query = this.query;
      const categoryID = this.queryCategoryID;
      const page = this.page;
      const r = await this.productApi.findProducts({
        query: this.query,
        categoryID: this.queryCategoryID,
        page: this.page,
        pageSize: this.pageSize,
      });
      if (
        page !== this.page ||
        query !== this.query ||
        categoryID !== this.queryCategoryID
      ) {
        return;
      }
      const data = r.data;
      this.page = data.page;
      this.pageSize = data.page_size;
      this.items = data.list;
      this.total = data.total;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
