<swiper-container
  grab-cursor="true"
  centered-slides="false"
  slides-per-view="auto"
  autoplay-delay="5000"
  autoplay-disable-on-interaction="false"
  #swiper
>
  @for (image of images; track $index) {
  <swiper-slide (click)="view($index)" appSlideup [slideUpDelay]="$index * 100">
    <img src="{{ image | fileurl }}" alt="product image {{ $index + 1 }}" />
  </swiper-slide>
  }
</swiper-container>
<div class="pagination">
  <button
    mat-icon-button
    (click)="swiper.swiper.slidePrev()"
    aria-label="slide to previous element"
  >
    <mat-icon>chevron_left</mat-icon>
  </button>

  <button
    mat-icon-button
    (click)="swiper.swiper.slideNext()"
    aria-label="slide to next element"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
